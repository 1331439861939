if ("requestIdleCallback" in window) {
    requestIdleCallback(initInputJS)
} else {
    setTimeout(initInputJS, 300)
}

function initInputJS() {
    initFormElements()
    initSelectElements()
}

function initFormElements() {
    const inputs = document.querySelectorAll(".form-element.blade")

    const adjustTopLabel = (input) => {
        const inputEl = input.querySelector(".input-field > span:not(.input-error-message)")
        const inputField = input.querySelector(".input-field")
        const hasValue = input.querySelector("input, textarea").value

        if (inputEl) {
            inputEl.style.display = hasValue ? "block" : "none"
            inputField.classList.toggle("form-input-top-label", hasValue)
        }
    }

    const handleInputEvent = function () {
        const formElement = this.closest(".form-element")
        adjustTopLabel(formElement)
    }

    const handleFocusEvent = function () {
        this.closest(".form-element").classList.add("form-element-active")
    }

    const handleBlurEvent = function () {
        this.closest(".form-element").classList.remove("form-element-active")
    }

    const handleInputChange = function () {
        const formElement = this.closest(".form-element")
        if (formElement.classList.contains("form-element-error")) {
            const spanEl = formElement.querySelector(".input-field > span")
            formElement.classList.remove("form-element-error")
            spanEl.classList.remove("input-error-message")
            spanEl.innerText = formElement.querySelector("input, textarea").getAttribute("placeholder")
            spanEl.style.display = "block"
        }
        adjustTopLabel(formElement)
    }

    // Инициализация полей формы
    inputs.forEach((input) => {
        adjustTopLabel(input)

        const textInput = input.querySelector("input, textarea")

        textInput.addEventListener("keyup", handleInputEvent)
        textInput.addEventListener("focus", handleFocusEvent)
        textInput.addEventListener("input", handleInputChange)
        textInput.addEventListener("blur", handleBlurEvent)

        input.tutkitValidate = function () {
            const textInput = this.querySelector("input, textarea")
            const rules = JSON.parse(this.dataset.validate)
            const inputField = this.querySelector(".input-field")
            const spanEl = inputField.querySelector("span")

            if (rules.required && !textInput.value) {
                spanEl.dataset.original = spanEl.innerText
                spanEl.innerText = rules.required
                spanEl.style.display = "block"
                inputField.classList.add("form-input-top-label")
                this.classList.add("form-element-error")
                return false
            }

            return true
        }
    })
}

function initSelectElements() {
    const selects = document.querySelectorAll("select")

    const handleSelectClick = (event) => {
        const select = event.currentTarget
        if (document.activeElement === select) {
            select.closest(".form-element-select.form-element").classList.toggle("form-element-active")
        }
    }

    selects.forEach((select) => {
        select.addEventListener("click", handleSelectClick, {passive: true})
    })
}
